/* App.css or your custom style file */
.footer {
  background-color: #e78335;
  text-align: center;
  font-family: "Poppins", sans-serif;
  color: white;
  padding: .75vw 0 .5vw 0;
  margin-top: 3vw;
}

.emaill {
  color: unset;
}
