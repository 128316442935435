.hero-container {
  top: 5%;
  /* Adjust based on your layout */
  left: 5%;
  /* Adjust based on your layout */
  margin: 6vw 0 0 0;
  /* Adjust based on your layout */
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  text-align: left;
  font-size: 3.7vw;
  /* Responsive font size */
  color: #e78335;
  font-family: "Poppins", sans-serif;


}

.blogo { /* th ebrand logo is large and should cover up 40vw of the screen */
  /* Responsive height */
  max-height: 400px;
  margin-bottom: 2.2vw;
  margin-left: -2vw;
}

@media (max-width: 1600px) {
  .blogo {
    width: 60vw;
  }
} 

.brand-name {
  position: relative;
  line-height: 1;
  /* Adjust line height as needed */
  font-family: "Bakbak One";

}

.brand-main {
  margin-block: 4%;
}

.address .email {
  position: relative;
  font-size: 2.5vw;
  /* Responsive font size */
  display: inline-block;
  max-width: 70%;
  /* Limit width to prevent overflow on small screens */
}
.email {
  margin-top: .4vw;
  font-size: 2vw;
  color: unset;
}

.phone-icon {
  position: relative;
  width: 2vw;
  /* Responsive width */
  height: 2vw;
  /* Responsive height */
  max-width: 40px;
  /* Set max width for smaller screens */
  max-height: 40px;
  /* Set max height for smaller screens */
  object-fit: cover;
}

.text {
  position: relative;
  font-weight: 600;
  font-size: 2vw;
  /* Responsive font size */
}

.contact-button {
  border: none;
  border-radius: 10px;
  /* Adjust border radius as needed */
  background-color: #e78335;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2vw;
  /* Responsive margin top */
  padding: .7vw 1.2vw;
  /* Responsive padding */
  gap: .8vw;
  /* Adjust gap between icon and text */
  color: #fff;
}

.contact-button:hover {
  background-color: #bf673b;
  color: #fff;
}

.contact-button .text {
  font-size: 1.5vw;
  /* Responsive font size */
  font-weight: 600;
}

/* Media Query for smaller screens */

@media (max-width: 768px) {
  .hero-container {
    /* Adjust font size for smaller screens */
    left: 2%;
    /* Adjust left position for smaller screens */

  }
  .phone-icon {
    width: 5vw;
    height: 5vw;
  }

  .address .email {
    font-size: 3.5vw;
    /* Adjust font size for smaller screens */
    max-width: 100%;
    /* Adjust max width for smaller screens */
  }

  .email {
    font-size: 3.3vw;
    color: unset;
  }

  
  .contact-button .text {
    font-size: 2.5vw;
    /* Adjust font size for smaller screens */
    margin-bottom: 2%;

  }

  .blogo {
    width: 70vw;
  }



  .contact-button {
    padding: 1.3 1.5vw;
    /* Adjust padding for smaller screens */
  }
}

@media (max-width: 460px) {
  .address {
    font-size: 6vw;
    /* Adjust font size for smaller screens */
    max-width: 100%;
    /* Adjust max width for smaller screens */
  }

  .blogo {
    width: 85vw;
  }
  .contact-button .text {
    font-size: 4.5vw;
    /* Adjust font size for smaller screens */
    margin-bottom: 2%;
    padding: 1.4 1.6vw;


  }
}