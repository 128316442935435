.item-container {
  width: calc(50% - 20px); /* Set the width for two items in a row with gap */
  box-sizing: border-box;
  background: #fafafacc;
  border-radius: 1vw;
  padding: .75em 1.25em;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.10);
  font-size: 1vw;
  flex-direction: row;
  backdrop-filter: blur(12px);

}

.item-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.item-name {
  color: #000000;

  font-weight: 600;
  margin-bottom: 8px;
}

.item-code {
  color: #000000;

  font-weight: 400;
}

.item-price {
  color: #000000;

  font-weight: 400;
  margin-top: 8px;
text-align: right;
  /* all text in same line */


}

@media (min-width: 600px) {


  .item-details {
    align-items: flex-start;

  }
}


@media (max-width: 1100px) {
  .item-container {
    font-size: 1.5vw; /* Adjust font size as needed */
    
  }
}

@media (max-width: 768px) {
  .item-container {
    width: 100%; /* On smaller screens, make items take up full width */
    font-size: 2vw; /* Adjust font size as needed */
    
  }
}

@media (max-width: 460px) {
  .item-container {
    font-size: 3.5vw; /* Adjust font size as needed */
    
  }
}