.parent {
  position: relative;
  margin-top: 6vw;
}

.menuu {
    display: flex;
    margin: 0 auto; /* Center the flex container */
    align-items: center;
    gap: 1vw; /* Adjust gap for spacing */
    flex-wrap: wrap;
    justify-content: center; /* Center items horizontally */
    max-width: 1600px;

}


.food-class {
    color: #2a2a2a;
    text-align: center;
    font-family: "Bakbak One";
    font-size: 3vw;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
  margin-bottom: 0; /* Add some bottom margin for spacing */
  margin-top: 2vw; /* Add some top margin for spacing */
}

.image {
  width: 15vw;
  height: 15vw;
  position: absolute;
  display: flex;
  left: 60vw;
  top: -4vw;
  -webkit-filter: drop-shadow(0px 4px 11px 0px rgba(0, 0, 0, 0.47));
  filter: drop-shadow(0px 4px 11px 0px rgba(0, 0, 0, 0.47));  object-fit: cover;
  z-index: -1;
  

}



/* Responsive styles */

@media (max-width: 1100px) {
.image {
  width: 18vw;
  height: 18vw;
}
}

@media (max-width: 768px) {
  .parent {
    margin-top: 10vw;

  }
  .food-class {
    font-size: 23px; /* Adjust the font size for smaller screens */
    text-align: left;
    justify-content:left;
    padding-left: 10px;
  }

  .image {
    width: 32vw;
    height: 32vw;
    left: 60%;
    top: -10vw;
  }

}


