.nav {
    display: flex;
    flex-direction: row;
    gap: 1vw;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    width: auto; /* Set the width to 100vw to make it responsive */
    max-width: 1560px; /* Set a maximum width if needed */
    margin: 0 auto; /* Center the nav horizontally */
    padding: 10px; /* Add some padding for better spacing */
    color: white;
    font-weight: 500;
  }
  
  .pill {
    color: rgb(255, 255, 255);
    background: #e78335;
    border-radius: .4vw;
    padding: 8px 16px; /* Adjust padding for better responsiveness */
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    position: relative;
    font-size: 1vw;
    border: none;
  }
  
  .pill:hover {
    background: #bf673b;
  }

  /* Media query for small screens */
  @media screen and (max-width: 768px) {
    .nav {
      justify-content: center; /* Center the pills on small screens */
      gap: 1.5vw; /* Adjust gap for better responsiveness */
    }
  
    .pill {
      font-size: 2vw;
        padding: 8px 16px; /* Adjust padding for better responsiveness */
    }
  }
  
  @media (max-width: 1100px) {
    .pill {
      font-size: 1.8vw; /* Adjust font size as needed */
      border-radius: 1vw;
    }
  }
  
@media (max-width: 460px) {
    .pill {
      font-size: 3.5vw; /* Adjust font size as needed */
      padding: 6px 12px; /* Adjust padding for better responsiveness */
      
    }
  }
  