
.App {
  font-family: "Poppins-Regular", sans-serif;
  margin: 8vw;
  margin-top: 3vw;

}


.menu-heading {
  color: #2a2a2a;
  text-align: center;
  font-family: "Bakbak One";
  font-size: 4vw;
  margin-top: 6vw;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}


.quick-button {
  position: fixed;
  bottom: 3vw;
  right: 3vw;
  border: none;
  border-radius: 10px;
  /* Adjust border radius as needed */
  background-color: #e78335;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 2vw;
  /* Responsive margin top */
  padding: 1vw 1.5vw;
  /* Responsive padding */
  gap: .8vw;
  box-shadow: 0px 4px 7px rgba(0, 0, 0, 0.10);
  /* Adjust gap between icon and text */
  color: #fff;
  font-weight: 600;
  font-size: 1.3vw;
}

.quick-button:hover {
  background-color: #bf673b;
  color: #fff;
}

.contact-button .textt {
  font-size: 3vw;
  /* Responsive font size */
  font-weight: 600;
}


@media (max-width: 1100px) {
  .App {
    margin: 4vw;
  }
}

@media (max-width: 768px) {
  .menu-heading {
    font-size: 34px;
    margin-top: 65px;
  }
  .quick-button .textt {
    font-size: 2.5vw;
    /* Adjust font size for smaller screens */
    margin-bottom: 2%;
    padding: 1.3 1.5vw;

  }
}


@media (max-width: 460px) {
  .App {
    margin-top: 15vw;
  }
  

}

@media (min-width: 1800px) {
  .App {
    margin: 0 7vw 0 7vw;
  }
}


@media (max-width: 460px) {
  .quick-button .textt {
    font-size: 4.5vw;
    /* Adjust font size for smaller screens */
    margin-bottom: 2%;

  }
  

}
